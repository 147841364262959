<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="container">
        <h1>404: Not Found</h1>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonToolbar, IonHeader } from '@ionic/vue'
export default {
  name: "404",
  components: {
    IonPage, IonContent, IonToolbar, IonHeader
  }
};
</script>
